import React from "react"
import PropTypes from "prop-types"

let Sidebar = ({ children }) => (
  <nav>
    <h2>Sidebar</h2>
    {children}
  </nav>
)

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Sidebar
